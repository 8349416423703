<template>
  <b-sidebar
    id="edit-client-sidebar"
    :visible="isEditProductSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    :width="$store.getters['app/currentBreakPoint'] === 'xs' ? '100%' : '420px'"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-edit-product-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ `${t('Edit Product')} #${id}` }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        v-show="!onSubmitProcess.open"
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- First Name -->
          <validation-provider
            #default="validationContext"
            :name="t('Name')"
            rules="required"
          >
            <b-form-group
              :label="t('Name')"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="productData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            :name="t('Group')"
            rules="required"
          >
            <b-form-group
              :label="t('Group')"
              label-for="group"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="productData.group"
                :state="getValidationState(validationContext)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="groups"
                label="group"
                input-id="group"
                taggable
                push-tags
                :clearable="false"
                @option:created="val => addOption(val, 'group')"
              >
                <template #list-header>
                  <li
                    v-if="optionsLoading"
                    class="text-center text-primary my-2"
                  >
                    <b-spinner
                      class="align-middle mr-1"
                    />
                    <strong>Загрузка...</strong>
                  </li>
                <!-- <li
                  v-if="!optionsLoading && groups.length === 0"
                  class="text-center text-primary my-2"
                >
                  <strong>Type for add new</strong>
                </li> -->
                </template>
              </v-select>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Last Name -->
          <validation-provider
            #default="validationContext"
            :name="t('Unit')"
            rules="required"
          >
            <b-form-group
              :label="t('Unit')"
              label-for="unit"
            >
              <v-select
                v-model="productData.unit"
                :state="getValidationState(validationContext)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="units"
                label="unit"
                input-id="unit"
                taggable
                push-tags
                :clearable="false"
                @option:created="val => addOption(val, 'unit')"
              >
                <template #list-header>
                  <li
                    v-if="optionsLoading"
                    class="text-center text-primary my-2"
                  >
                    <b-spinner
                      class="align-middle mr-1"
                    />
                    <strong>Загрузка...</strong>
                  </li>
                  <!-- <li
                v-if="!optionsLoading && groups.length === 0"
                class="text-center text-primary my-2"
              >
                <strong>Type for add new</strong>
              </li> -->
                </template>
              </v-select>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- manufacturer -->
          <validation-provider
            #default="validationContext"
            :name="t('Manufacturer')"
            rules=""
          >
            <b-form-group
              :label="t('Manufacturer')"
              label-for="manufacturer"
            >
              <v-select
                v-model="productData.manufacturer"
                :state="getValidationState(validationContext)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="manufacturers"
                label="manufacturer"
                input-id="manufacturer"
                taggable
                push-tags
                :clearable="false"
                @option:created="val => addOption(val, 'manifacture')"
              >
                <template #list-header>
                  <li
                    v-if="optionsLoading"
                    class="text-center text-primary my-2"
                  >
                    <b-spinner
                      class="align-middle mr-1"
                    />
                    <strong>Загрузка...</strong>
                  </li>
                  <!-- <li
                v-if="!optionsLoading && groups.length === 0"
                class="text-center text-primary my-2"
              >
                <strong>Type for add new</strong>
              </li> -->
                </template>
              </v-select>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- init_price -->
          <validation-provider
            #default="validationContext"
            :name="t('Purchase price')"
            rules="required"
          >
            <b-form-group
              :label="t('Purchase price')"
              label-for="init_price"
            >
              <b-form-input
                id="init_price"
                v-model="productData.init_price"
                :state="getValidationState(validationContext)"
                trim
                type="number"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- last_price -->
          <validation-provider
            #default="validationContext"
            :name="t('Sale price')"
            rules="required"
          >
            <b-form-group
              :label="t('Sale price')"
              label-for="last_price"
            >
              <b-form-input
                id="last_price"
                v-model="productData.last_price"
                :state="getValidationState(validationContext)"
                trim
                type="number"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- discount_price -->
          <validation-provider
            #default="validationContext"
            :name="t('Discount price')"
            rules="required"
          >
            <b-form-group
              :label="t('Discount price')"
              label-for="discount_price"
            >
              <b-form-input
                id="discount_price"
                v-model="productData.discount_price"
                :state="getValidationState(validationContext)"
                trim
                type="number"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Contact -->
          <b-form-group
            label="Description"
            label-for="description"
          >
            <b-form-textarea
              id="description"
              v-model="productData.description"
              type="text"
              placeholder=""
              trim
            />
          </b-form-group>

          <!-- code -->
          <validation-provider
            #default="validationContext"
            :name="t('Code')"
            rules="required"
          >
            <b-form-group
              :label="t('Code')"
              label-for="code"
            >
              <b-form-input
                id="code"
                v-model="productData.code"
                :state="getValidationState(validationContext) && codeDuplicated"
                trim
                @input="val => checkForCode(val)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] || 'Code Duplicated' }}
              </b-form-invalid-feedback>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="onSubmitProcess.save"
            >
              <b-spinner
                v-if="onSubmitProcess.save"
                small
                type="grow"
              />
              {{ t('Add') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              :disabled="onSubmitProcess.save"
              @click="hide"
            >
              {{ t('Cancel') }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
      <div
        v-show="onSubmitProcess.open"
      >
        <div class="text-center text-primary my-2">
          <b-spinner
            class="align-middle mr-1"
          />
          <strong>{{ t('Загрузка') }}...</strong>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BSpinner, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { avatarText } from '@core/utils/filter'
import { ref, watch } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
// i18n
import { useUtils as useI18nUtils } from '@core/libs/i18n'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BSpinner,
    BFormTextarea,
    // BMedia,
    // BAvatar,
    // BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEditProductSidebarActive',
    event: 'update:is-edit-product-sidebar-active',
  },
  props: {
    isEditProductSidebarActive: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      default: () => null,
      // required: true,
    },
    typeOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    }
  },
  setup(props, { emit }) {
    const { t } = useI18nUtils()
    const toast = useToast()

    const onSubmitProcess = ref({
      save: false,
      open: false,
    })

    const blankProductData = {
      group: '',
      name: '',
      manufacturer: '',
      unit: '',
      init_price: 0,
      last_price: 0,
      discount_price: 0,
      description: '',
      code: '',
    }

    const productData = ref(JSON.parse(JSON.stringify(blankProductData)))

    watch(() => props.isEditProductSidebarActive, val => {
      if (val === false) {
        emit('update:id', null)
      }
    })

    watch(() => props.id, val => {
      console.log('asdawd', val)
      if (val != null) {
        onSubmitProcess.value.open = true
        store.dispatch('app-product/fetchProduct', {
          id: props.id,
        })
          .then(response => {
            const product = response.data.records[0]
            productData.value = {
              group: product.group,
              name: product.name,
              manufacturer: product.manufacturer,
              unit: product.unit,
              init_price: product.init_price,
              last_price: product.last_price,
              discount_price: product.discount_price,
              description: product.description,
              code: product.code,
            }
          // console.log(response.data.battery.station)
          })
          .catch(error => {
            if (error.response.status === 404) {
              productData.value = undefined
            }
          })
          .finally(() => {
            onSubmitProcess.value.open = false
          })
      }
    })

    const resetProductData = () => {
      productData.value = JSON.parse(JSON.stringify(blankProductData))
    }

    const groups = ref([])
    const manufacturers = ref([])
    const units = ref([])
    const optionsLoading = ref(true)
    store.dispatch('app-product/fetchOptions')
      .then(response => {
        groups.value = response.data.records.map(item => item.group).filter(item => item != null)
        manufacturers.value = response.data.records.map(item => item.manifacture).filter(item => item != null)
        units.value = response.data.records.map(item => item.unit).filter(item => item != null)
      })
      .finally(() => {
        optionsLoading.value = false
      })

    const addOption = (value, column) => {
      console.log(value, column)
      const payload = { }
      payload[column] = value
      store.dispatch('app-product/addOption', payload)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Вы успешно добавили новый выбор',
            },
          })
        })
    }

    const onSubmit = () => {
      onSubmitProcess.value.save = true
      store.dispatch('app-product/updateProduct', { id: props.id, data: productData.value })
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Вы успешно нажали на кнопку сохранить и продать.',
            },
          })
          emit('refetch-data')
          emit('update:is-edit-product-sidebar-active', false)
        })
        .finally(() => {
          onSubmitProcess.value.save = false
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetProductData)

    const codeDuplicated = ref(false)
    const checkForCode = code => {
      store.dispatch('app-product/checkForCode', code)
        .then(response => {
          if (response.data.records.length !== 0) {
            codeDuplicated.value = false
          } else {
            codeDuplicated.value = true
          }
        })
    }

    const resolveUserAvatarVariant = status => {
      // if (status === 'New') return 'primary'
      // // if (status === 'Closed') return 'danger'
      // // if (status === 'Downloaded') return 'secondary'
      // if (status === 'Draft') return 'warning'
      // if (status === 'Sent') return 'info'
      if (status === 1) return 'success'
      return 'danger'
    }

    return {
      productData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      onSubmitProcess,
      t,
      resolveUserAvatarVariant,
      avatarText,
      groups,
      manufacturers,
      units,
      optionsLoading,
      addOption,
      checkForCode,
      codeDuplicated,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#edit-client-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
